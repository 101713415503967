<template>
  <div class="promotion-manage-coupon-list">
    <en-table-layout :tableData="pageData.data" @selection-change="handleSelectionChange2">
      <template slot="toolbar">
        <template v-if="this.$route.name == 'couponList'">
          <el-form :inline="true" :model="params" class="demo-form-inline">
            <el-form-item label="状态">
              <el-select size="medium" v-model="couponParams.status">
                <el-option label="全部" value=""></el-option>
                <el-option label="启用中" :value="1"></el-option>
                <el-option label="禁用中" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-input style="width: 360px" v-model.trim="searchKeyWord" placeholder="请输入关键字进行搜索" clearable>
                <el-select style="width: 120px;" v-model="tempKeyWord" slot="prepend">
                  <el-option label="优惠券名称" value="coupon_name"></el-option>
                  <el-option label="券码ID" value="coupon_code"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" size="small" @click="searchCoupon">搜索</el-button>
          <div class="col"></div>
          <el-button type="primary" size="small" @click="handleAddCoupon">添加优惠券</el-button>
        </template>
        <template v-else>
          <el-form :inline="true" :model="params" class="demo-form-inline">
            <el-form-item label="状态">
              <el-select size="medium" v-model="params.status">
                <el-option label="全部" value=""></el-option>
                <el-option label="已启用" value="1"></el-option>
                <el-option label="已禁用" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键词">
              <el-input size="medium" v-model="params.card_name" placeholder="请输入关键词"></el-input>
            </el-form-item>
          </el-form>

          <el-button type="primary" size="small" @click="searchCard">搜索</el-button>

          <div class="col"></div>

          <el-button type="primary" size="small" @click="handleAddCoupon">添加优惠券</el-button>
        </template>
      </template>
      <template slot="table-columns">
        <template v-if="this.$route.name == 'couponList'">
          <el-table-column label="优惠券名称">
            <template slot-scope="scope">{{ scope.row.coupon_name }}</template>
          </el-table-column>
          <el-table-column label="优惠券类型" width="100">
            <!-- '优惠类型 1:折扣  2:满减' -->
            <template slot-scope="scope">{{ scope.row.coupon_type == 1 ? '折扣券' : '满减券' }}</template>
          </el-table-column>
          <el-table-column label="优惠内容">
            <template slot-scope="scope">{{ scope.row.coupon_content }}</template>
          </el-table-column>
          <el-table-column label="有效期" width="100">
            <template slot-scope="scope">
              {{ scope.row.valid_timelong + (valid_timelong_unit_filter(scope.row.valid_timelong_unit)) }}
            </template>
          </el-table-column>

          <el-table-column label="兑换码总数" width="100">
            <template slot-scope="scope">
              {{ scope.row.key_num ? scope.row.key_num : 0 }}
            </template>
          </el-table-column>
          <el-table-column label="创建时间" width="150">
            <template slot-scope="scope">
              {{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column label="状态" width="80">
            <template slot-scope="scope">
              {{ scope.row.status === 1 ? '启用中' : '禁用中' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="220" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="distribution(scope.$index, scope.row)">管理
              </el-button>
              <el-button size="mini" type="danger" @click="editCoupon(scope.$index, scope.row)">编辑
              </el-button>
              <el-button size="mini" type="danger" @click="putCouponsUpdateStatuso(scope.row)">
                {{ scope.row.status ? '禁用' : '启用' }}
              </el-button>
            </template>
          </el-table-column>
        </template>
        <!-- <template v-else>
          <el-table-column label="卡卷名称" width="200" fixed="left">
            <template slot-scope="scope">{{ scope.row.card_name }}</template>
          </el-table-column>
          <el-table-column label="所属商城" width="150" fixed="left">
            <template slot-scope="scope">{{ scope.row.shop_name }}</template>
          </el-table-column>
          <el-table-column label="创建时间" width="150">
            <template slot-scope="scope">
              {{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column label="有效期" width="300">
            <template slot-scope="scope">
              {{ scope.row.start_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}
              <span class="ml-1 mr-1">~</span>
              {{ scope.row.end_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}
            </template>
          </el-table-column>

          <el-table-column label="兑换码总数" width="100">
            <template slot-scope="scope">
              {{ scope.row.key_num ? scope.row.key_num : 0 }}
            </template>
          </el-table-column>
          <el-table-column label="状态" width="80">
            <template slot-scope="scope">
              {{ scope.row.status === 1 ? '启用中' : '禁用中' }}
            </template>
          </el-table-column>
          <el-table-column label="管理" min-width="300" fixed="right">
            <template slot-scope="scope">
              <el-button
              size="mini"
              type="primary"
              v-if="$store.getters.shopInfo.shop_type == 3 ? true : false"
              @click="distribution(scope.$index, scope.row)"
              >在线发放</el-button
            >
              <el-button size="mini" type="primary" @click="gotwo(scope.row.id)">生成
              </el-button>
              <el-button size="mini" @click="goDhm(scope.$index, scope.row)">兑换码
              </el-button>
              <el-button size="mini" type="danger" @click="handleEdit(scope.$index, scope.row)">编辑
              </el-button>
              <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button>
              <el-button size="mini" type="danger" @click="handleDeleteStatus(scope.row)">{{ scope.row.status === 1 ? '禁用'
                : '启用' }}
              </el-button>
            </template>
          </el-table-column>
        </template> -->
      </template>

      <el-pagination slot="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="currentPage3" :page-size="params.page_size" :page-sizes="MixinPageSizes" :layout="MixinTableLayout"
        background :total="pageData.data_total"></el-pagination>
    </en-table-layout>

    <grant ref="grant"></grant>
  </div>
</template>

<script>
import * as API_coupon from '@/api/coupon';
import grant from './components/grant';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';

export default {
  components: {
    EnTableLayout,
    grant
  },
  data () {
    return {
      total: 0,
      flag: true,
      params: {
        page_no: 1,
        page_size: 20,
        mall_type: '',
        shopDisable: '',
        keyword: '',
        card_type: 2,
        card_name: '',
        status: ''
      },
      couponParams: {
        page_no: 1,
        page_size: 20,
        status: '',
        coupon_code: ''
      },
      tempKeyWord: 'coupon_name', // 关键词选项
      searchKeyWord: '', // 关键词搜索
      currentPage3: 1,
      selectedData: [],
      pageData: { data: [] },
      search: '',
      czkmsg: '',
      formInline: {
        card_name: '',
        page_no: 1,
        page_size: 20,
        status: ''
      }
    };
  },
  watch: {
    $route: function () {
      if (this.$route.name === 'couponList') {
        this.params.card_type = 2;
        this.flag = true;
        // this.getyhj();
        this.getCouponsInfo()
      } else if (this.$route.name === 'cashCouponList') {
        this.params.card_type = 3;
        this.flag = false;
        this.getyhj();
      } else {
        this.params.card_type = 4;
        this.flag = false;
        this.getyhj();
      }
    },
    searchKeyWord: {
      immediate: true,
      handler (val) {
        // 先清空再赋值
        this.couponParams.coupon_name = null
        this.couponParams.coupon_code = null
        this.couponParams[this.tempKeyWord] = val
        // console.log('tempKeyWord', this.couponParams);
      }
    },
    tempKeyWord: {
      immediate: true,
      handler (val) {
        // 先清空再赋值
        this.couponParams.coupon_name = null
        this.couponParams.coupon_code = null
        this.couponParams[val] = this.searchKeyWord
        // console.log('tempKeyWord', this.couponParams);
      }
    }
  },
  created () {
    if (this.$route.name == 'couponList') {
      this.flag = true;
      this.params.card_type = 2;
      // this.getyhj();
      this.getCouponsInfo()
    } else if (this.$route.name == 'czkMealList') {
      this.flag = false;
      this.params.card_type = 4;
      this.getyhj();
    } else {
      this.flag = false;
      this.params.card_type = 3;
      this.getyhj();
    }
    // this.getyhj();
  },
  methods: {
    valid_timelong_unit_filter (value) {
      if (value == 1) {
        return '个月'
      } else if (value == 2) {
        return '年'
      } else {
        return '天'
      }
    },
    /** 添加优惠券 */
    handleAddCoupon () {
      this.$router.push({
        path: '/tools/promotion-manage/coupon-edit',
        query: { source: 'coupon' }
      });
    },
    /** 编辑优惠券 */
    handleEdit (index, row) {
      if (row.card_type == 3) {
        this.$router.push({
          path: '/tools/promotion-manage/cash-coupon-edit',
          query: { id: row.id }
        });
      } else if (row.card_type == 2) {
        this.$router.push({
          path: '/tools/promotion-manage/coupon-edit',
          query: { id: row.id }
        });
      } else {
        this.$router.push({
          path: '/application/czkadd',
          query: { id: row.id }
        });
      }
    },
    // 编辑优惠券
    editCoupon (index, row) {
      this.$router.push({
        path: '/tools/promotion-manage/coupon-edit',
        query: { source: 'coupon', id: row.id }
      });
    },
    distribution (index, row) {
      this.$refs.grant.show(row);
    },
    // goDhm (index, row) {
    //   if (this.$route.name == 'czkMealList') {
    //     this.$router.push({
    //       path: '/application/exchangeCode1',
    //       query: { card_id: row.id }
    //     });
    //   } else {
    //     this.$router.push({
    //       path: '/tools/promotion-manage/exchangeCode',
    //       query: { card_id: row.id }
    //     });
    //   }
    // },
    // gotwo (id) {
    //   if (this.$route.name == 'cashCouponList') {
    //     this.$router.push({
    //       path: '/tools/promotion-manage/cash-coupon-edit',
    //       query: {
    //         active: 2,
    //         card_id: id
    //       }
    //     });
    //   } else if (this.$route.name == 'czkMealList') {
    //     this.$router.push({
    //       path: '/application/czkadd',
    //       query: {
    //         active: 2,
    //         card_id: id
    //       }
    //     });
    //   } else {
    //     this.$router.push({
    //       path: '/tools/promotion-manage/coupon-edit',
    //       query: {
    //         active: 2,
    //         card_id: id
    //       }
    //     });
    //   }
    // },
    searchCard () {
      this.formInline.card_type = this.params.card_type;
      API_coupon.getDhmlist(this.params).then(res => {
        this.pageData = res;
      });
    },
    getyhj () {
      let params = this.params;
      API_coupon.getDhmlist(params).then(res => {
        this.pageData = res;
      });
    },
    /** 获取优惠券列表 */
    async getCouponsInfo () {
      const { data } = await API_coupon.getCouponsInfo(this.couponParams)
      // console.log('优惠券数据', data);
      this.pageData = data
    },
    /** 搜索优惠券信息 */
    searchCoupon () {
      this.couponParams.page_no = 1
      this.getCouponsInfo()
    },
    /** 启用/禁用单个优惠券 */
    async putCouponsUpdateStatuso (row) {
      let { id, status } = row;
      this.$confirm(
        `确定要${status === 1 ? '禁用' : '启用'}该优惠券吗?`,
        '提示',
        { type: 'warning' }
      ).then(async () => {
        const res = await API_coupon.putCouponsUpdateStatuso({ id, status: status === 1 ? 0 : 1 })
        this.getCouponsInfo()
        this.$message({
          type: 'success',
          message: `操作成功!`
        });
      })
        .catch(() => {
        });
    },
    handleSelectionChange2 (val) {
      this.selectedData = val;
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.couponParams.page_size = val
      this.getCouponsInfo()
    },
    handleCurrentChange (val) {
      this.currentPage3 = val;
      // this.params.page_no = val;
      // this.getyhj();
      this.couponParams.page_no = val
      this.getCouponsInfo()
      // console.log(`当前页: ${val}`);
    },
    handleDeleteStatus (row) {
      let {
        id,
        status
      } = row;
      // updateStatus
      this.$confirm(
        `确定${status === 1 ? '禁用' : '启用'}此卡券，是否继续?`,
        '提示',
        { type: 'warning' }
      )
        .then(() => {
          API_coupon.updateStatus({
            id: id,
            status: status === 1 ? 0 : 1
          }).then(res => {
            this.getyhj();
            this.$message.success('操作成功！');
          });
        })
        .catch(() => {
        });
    },
    handleDelete (index, row) {
      let id = [row.id];
      this.$confirm(`确定删除此卡券，是否继续?`, '提示', { type: 'warning' })
        .then(() => {
          API_coupon.cardDelete({ ids: id }).then(res => {
            this.getyhj();
          });
        })
        .catch(() => {
        });
    },
  }
};
</script>

<style lang="scss"></style>
